import React from "react";
import { useState, useEffect } from "react";
import "../App.css";

//The ModuleParts component, renders the list of ModuleParts that can be licensed and calculates the prices based on the selected modules
const ModuleParts = ({
  modulePartsData,
  selectedModulePartIds,
  onModulePartSelection,
  numberOfLocations,
  licenseWasSent,
}) => {
  const [license_cost_onetime, setLicenseCostOnetime] = useState(0.0);
  const [license_cost_monthly, setLicenseCostMonthly] = useState(0.0);

  useEffect(() => {
    // Update license costs when selectedModulePartIds or modulePartsData changes
    setLicenseCostOnetime(
      calculateTotalOnetimeCost(modulePartsData, numberOfLocations)
    );
    setLicenseCostMonthly(
      calculateTotalMonthlyCost(modulePartsData, numberOfLocations)
    );
  }, [modulePartsData, selectedModulePartIds, numberOfLocations]);

  // Calculates the total monthly cost based on the checked modules
  const updateModulePartSelection = (event, modulePart) => {
    onModulePartSelection(modulePart, event.target.checked);

    setLicenseCostOnetime(
      calculateTotalOnetimeCost(modulePartsData, numberOfLocations)
    );
    setLicenseCostMonthly(
      calculateTotalMonthlyCost(modulePartsData, numberOfLocations)
    );
  };

  return (
    <div id="form-licenses">
      <h2>Ihre Lizenzen</h2>
      <table id="table-advomodules">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Beschreibung</th>
            <th>Preis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="4" className="advomodule-root">
              Notwendige Module
            </td>
          </tr>

          {/* Required ModuleParts */}
          {modulePartsData
            .filter((modulePart) => modulePart.required)
            .map((modulePart) => (
              <tr key={modulePart.id} className="advo-module">
                <td>
                  <input
                    type="checkbox"
                    checked={modulePart.required}
                    disabled={modulePart.required}
                    onChange={(event) =>
                      updateModulePartSelection(event, modulePart)
                    }
                  />
                </td>
                <td>{modulePart.modulePartName}</td>
                <td>{modulePart.description}</td>
                <td
                  className="module-price"
                  payment_type={modulePart.onetime ? "onetime" : "monthly"}
                >
                  {Number(modulePart.modulePartPrice).toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                  })}
                  {modulePart.onetime ? "(einmalig)" : ""}
                </td>
              </tr>
            ))}

          <tr>
            <td colSpan="4" className="advomodule-root">
              Optionale Module
            </td>
          </tr>

          {/* Optional ModuleParts */}
          {modulePartsData
            .filter((modulePart) => !modulePart.required)
            .map((modulePart) => (
              <tr key={modulePart.id} className="advo-module">
                <td>
                  <input
                    type="checkbox"
                    checked={isModulePartIdChecked(modulePart.id)}
                    onChange={(event) =>
                      updateModulePartSelection(event, modulePart)
                    }
                    disabled={licenseWasSent}
                  />
                </td>
                <td>{modulePart.modulePartName}</td>
                <td>{modulePart.description}</td>
                <td
                  className="module-price"
                  payment_type={modulePart.onetime ? "onetime" : "monthly"}
                >
                  {modulePart.modulePartPrice.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                  })}
                  {modulePart.onetime ? "(einmalig)" : ""}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <table id="table-prices">
        <tbody>
          <tr>
            <th>Monatliche Lizenzgebühren</th>
            <td id="license-cost-monthly">
              {license_cost_monthly.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
              })}
            </td>
          </tr>

          <tr>
            <th>Einmaliger Betrag (Lizenzgebühren)</th>
            <td id="license-cost-onetime">
              {license_cost_onetime.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  // Checks if a modulePartId is in the selectedModulePartIds array and gives it the 'checked' attribute
  function isModulePartIdChecked(modulePartId) {
    if (selectedModulePartIds && selectedModulePartIds.includes(modulePartId)) {
      modulePartsData.find(
        (modulePart) => modulePart.id === modulePartId
      ).checked = true;
      return true;
    } else {
      modulePartsData.find(
        (modulePart) => modulePart.id === modulePartId
      ).checked = false;
      return false;
    }
  }
};

// Calculates the total monthly cost based on the checked modules
const calculateTotalMonthlyCost = (modulePartsData, numberOfLocations) => {
  return (
    modulePartsData
      .filter(
        (modulePart) =>
          (modulePart.required || modulePart.checked) && !modulePart.onetime
      )
      .reduce((total, modulePart) => total + modulePart.modulePartPrice, 0) *
    numberOfLocations
  );
};

// Calculates the total monthly cost based on the checked modules
const calculateTotalOnetimeCost = (modulePartsData, numberOfLocations) => {
  return (
    modulePartsData
      .filter(
        (modulePart) =>
          (modulePart.required || modulePart.checked) && modulePart.onetime
      )
      .reduce((total, modulePart) => total + modulePart.modulePartPrice, 0) *
    numberOfLocations
  );
};

export default ModuleParts;
