import React from "react";

// JSON schema for customer data form
const customerDataSchema = {
  firstname: { label: "Vorname", type: "text", maxLength: 255 },
  lastname: { label: "Nachname", type: "text", maxLength: 255, required: true },
  name: { label: "Kanzlei", type: "text", maxLength: 255, required: true },
  street: {
    label: "Straße und Hausnummer",
    type: "text",
    maxLength: 255,
    required: true,
  },
  postcode: { label: "PLZ", type: "text", maxLength: 255, required: true },
  location: { label: "Ort", type: "text", maxLength: 255, required: true },
  phoneNumber: { label: "Tel", type: "tel", maxLength: 40, required: true },
  fax: { label: "Fax", type: "tel", maxLength: 40 },
  email: { label: "Email", type: "email", maxLength: 255, required: true },
  numberOfLocations: {
    label: "Anzahl Standorte",
    type: "number",
    min: 1,
    required: true,
  },
};

//The CustomerData component, renders the customer data form
const CustomerData = ({
  customerData,
  handleCustomerDataChange,
  licenseWasSent,
}) => {
  return (
    <div id="customer-data">
      <h2>Ihre Kundendaten</h2>

      {customerData && (
        <>
          {/*maps customerData schema into form and sets the unique parameters for each field*/}
          {Object.entries(customerDataSchema).map(([key, config]) => (
            <React.Fragment key={key}>
              <div>
                <label
                  className={getLabelClass(
                    customerData[key],
                    config.required,
                    licenseWasSent
                  )}
                >
                  {config.label}
                  {getLabelClass(
                    customerData[key],
                    config.required,
                    licenseWasSent
                  ) === "label-state-invalid"
                    ? "*"
                    : ""}
                </label>
                <input
                  type={config.type}
                  name={key}
                  value={customerData[key]}
                  maxLength={config.maxLength}
                  min={config.min}
                  onChange={handleCustomerDataChange}
                  readOnly={licenseWasSent}
                  className={getInputClass(
                    customerData[key],
                    config.required,
                    licenseWasSent
                  )}
                  {...(config.required && { required: true })}
                />
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

//Determine the css class for the label based on the value and the licenseWasSent state
function getLabelClass(value, required, licenseWasSent) {
  if ((value === undefined || value === "") && required) {
    return "label-state-invalid";
  } else if (licenseWasSent) {
    return "label-state-disabled";
  } else {
    return "label-state-valid";
  }
}

//Determine the css class for the input based on the value and the licenseWasSent state
function getInputClass(value, required, licenseWasSent) {
  if ((value === undefined || value === "") && required) {
    return "input-state-invalid";
  } else if (licenseWasSent) {
    return "input-state-disabled";
  } else {
    return "input-state-valid";
  }
}

export default CustomerData;
